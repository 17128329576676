/*css file*/
.dataTables_paginate {
    margin-top: 20px;
}

.dataTable td {
    max-width: 40px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.cardBodyCenterText {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border: dashed;
}

.centerItems{
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.centerModal {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    display: block;
    width: 100%;
    top: 50%;
}

#vendor_file_import_details_table_div .bs-select{
    display: none;
}

.username.nav-link:hover, .username.nav-link:focus{
    color:white !important;
    opacity: 0.8;
}

.ag-grid-div{
    height: 65vh !important;
    margin-top: 5px;

}
