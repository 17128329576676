
.wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 460px;
}
.dd-wrapper {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  margin-right: 20px;
  width: 300px;
}
.dd-header {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  line-height: 30px;
  border-radius: 4px;
  position: relative;
  background-color: rgba(255,255,255,0.1);
  transition: background-color ease-in-out 300ms;
  color: white;
}
.dd-header:hover {
  background-color: rgba(255,255,255,0.3);
}
.dd-header span {
  margin-right: 20px;
}
.dd-header-title {
  font-weight: 300;
  margin: 2px 9px;
  margin-right: 30px;
}

.angle-down {
  color: #000;
  margin-right: 20px;
}
.dd-list {
  z-index: 10;
  /*position: absolute;*/
  width: 100%;
  border: 1px solid #dfdfdf;
  border-top: none;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 5px -1px #e8e8e8;
  box-shadow: 0 2px 5px -1px #e8e8e8;
  font-weight: 400;
  padding: 0px;
  max-height: 215px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
.dd-list-item {
  cursor: pointer;
  width: 100%;
  padding: 4px 8px;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.dd-list-item.selected {
  color: #fff;
  background-color: #ffcc01;
}
.dd-list-item:hover {
  color: #fff;
  background-color: #c8ced3;
}
.dd-wrapper-single {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  width: 265px;
}
.dd-wrapper-single .dd-header {
  border: 1px solid #ccc;
}
.dd-wrapper-single .dd-header .dd-header-name {
  font-weight: 400;
}
.dd-wrapper-single .dd-list {
  border: 1px solid #ccc;
  border-top: none;
}

.dd-list-container {
  padding-top: 5px;
}

.dd-list-select-button-container {
  //padding-top: 2px;
}

.dd-list-select-button {
  color: white;
  text-align: center;
  width: 50%;
  position: relative;
  font-weight: 400;
  border-radius: 4px;
}

.dd-submit {
  background-color: #20A8D8;
  border: 1px solid #ccc;
  color: white;
  //margin-left: 1%;
}

.dd-submit:hover {
  background-color: dodgerblue;
  color: white;
}

.dd-reset {
  background-color: lightgray;
  border: 1px solid #ccc;
  color: black;
  width: 50%;
}

.dd-reset:hover {
  background-color: dodgerblue;
  color: white;
}

.dd-search-box {
  width: 100%;
}

.dd-search-box>input:focus{
  border: none;
}

.dd-dropdown {
  width: 100%;
  z-index: 1000;
  position: absolute;
}
