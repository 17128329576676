@mixin breakpoint($class) {
    @if $class == xs {
        @media (max-width: 547px) {
            @content;
        }
    } @else if $class == sm {
        @media (min-width: 768px) {
            @content;
        }
    } @else if $class == md {
        @media (min-width: 1290px) {
            @content;
        }
    } @else if $class == lg {
        @media (min-width: 1400px) {
            @content;
        }
    } @else {
        @warn "Breakpoint mixin supports: xs, sm, md, lg";
    }
}

.cookie-wrapper {    
    width: auto;
    height: auto;
    font-family: myriad-pro, sans-serif;
    color: #6a737b;
    scroll-behavior: smooth;
    line-height: 1;
    position: absolute;
    z-index: 9;

    @include breakpoint(xs) {
        font-size: 14px;
    }
    @include breakpoint(sm) {
        font-size: 14px;
    }
    @include breakpoint(md) {
        font-size: 15px;
    }
    @include breakpoint(lg) {
        font-size: 16px;
    }
    justify-content: center;
    align-items: center;

    .faq-modal {
        margin-top: 4%;
        ul {
            .dashed {
                list-style-type: none;
                padding-left: 1.2rem;
              }
            .dashed > li {
                position: relative;
                padding-left: 1.2em;
              }
            .dashed > li:before {
                content: "✓";
                position: absolute;
                left: 0;
              }
          } 
        @include breakpoint(xs) {
            margin-top: 3.4rem;
        }
    }
}